import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <ContentContainer>
        <h1>404 - Page Not Found</h1>
        <p>
          You just hit a page that doesn&#39;t exist. Return to{" "}
          <Link to="/">homepage</Link>.
        </p>
      </ContentContainer>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
